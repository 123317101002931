ion-slides {
    height: 100%;
}

ion-slide {
    display: block !important;
}

ion-slide h2 {
    color: var(--ion-color-primary);
}

ion-slide ion-button {
    --background: var(--ion-color-tertiary);
    --border-radius: 4px;
}

ion-slide img {
    max-height: 50%;
    max-width: 60%;
    margin: 36px 0;
    pointer-events: none;
}

ion-slide p {
    padding: 0 40px;
    font-size: 16px;
    line-height: 1.5;
    color: var(--ion-color-step-600, #60646b);
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
}

.study-list-wrapper {
    display: flex;
    flex-direction: row;
    flex: 0 0 100%;
    gap: 0px;
    height: 100%;
    margin: 10;
    padding: 40;
    overflow: hidden;
    background-color: #f5f5f5;
}

.blurb {
    padding: 20px;
    margin-top: 10px;
    background: #f5f5f5;
    border-radius: 5px;
    color: #262626;
}

.search-filter {
    display: flex;
    flex-direction: row;
    flex: 0 0 100%;
    gap: 20px;
    margin-top: 10px;
    --padding-start: 10px;
    --inner-padding-end: 10px;
}

.search-filter .search-wrapper {
    display: flex;
    flex-grow: 1;
    padding: 0 20px;
    border-radius: 35px;
    border: 1px solid #e8e8e8;
    align-items: center;
}

.search-filter .search-wrapper .search {
    font-size: 14px;
}

.search-filter .filter-icon {
    display: flex;
    margin-left: 10px;
    flex-grow: 0;
    align-items: center;
    gap: 5px;
}

@media (min-width: 768px) {
    .search-filter .filter-icon {
        display: none;
    }
}

.study-list-filters {
    flex-shrink: 0;
    overflow-y: hidden;
    padding: 0 20px;
    box-sizing: content-box;
}

.study-filters-wrapper {
    display: flex;
    flex-direction: column;
    flex: 0 0 100%;
}

.studies-list {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-y: scroll;
    background-color: #fff;
}

@media (min-width: 768px) {
    .studies-list {
        border-left: 1px solid #e7e6e7;
    }
}

.study-filters {
    display: flex;
    flex-direction: column;
    gap: 0px;
    margin-top: 20px;
}

.study-filters .filter-title {
    padding: 10px 15px;
    color: #316095;
}

.study-filters .filter-wrapper {
    display: flex;
    flex-direction: row;
    gap: 15px;
    align-items: center;
    flex-basis: auto;
}

@media (min-width: 768px) {
    .study-filters .filter-wrapper {
        height: 32px;
        --background: #f5f5f5;
    }
}

@media (min-width: 768px) {
    .study-filters .filter-checkbox {
        --size: 16px;
        margin: 0;
    }
    .study-filters .filter-label {
        margin: 0;
        font-size: 14px;
    }
}

.applied-filters-count {
    background: #316095;
    border-radius: 30px;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
}
.action-button {
    height: 2.4em;
    margin: 0;
}

.refresh-icon {
    display: flex;
    margin-left: 10px;
    flex-grow: 0;
    align-items: center;
    gap: 5px;
}

.refresh-icon:hover {
    color: #656565;
    cursor: pointer;
}

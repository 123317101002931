#main-tabs ion-icon.uriux {
    background-repeat: no-repeat;
    background-position: 50%;
    height: 60%;
    background-image: url('../assets/uriux-icon.png');
    background-size: contain;
}

.uri-page ion-content {
    --padding-start: var(--uri-content-padding-horizontal);
    --padding-end: var(--uri-content-padding-horizontal);
}

.uri-page ion-content.no-padding {
    --padding-start: 0px;
}

.uri-page ion-content > ion-list {
    margin-left: calc(-1 * var(--uri-content-padding-horizontal));
    margin-right: calc(-1 * var(--uri-content-padding-horizontal));
}

.uri-page.unauthenticated ion-menu-button {
    --color: var(--ion-color-white);
}

.uri-page.unauthenticated ion-toolbar {
    --min-height: var(--uri-toolbar-height);
    --background: url('../assets/img/uriux-portal-logo.svg') center center var(--ion-color-secondary) no-repeat;
}

.uri-page.unauthenticated .page-image {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.uri-page.authenticated ion-footer > ion-toolbar {
    padding-left: 20px;
    padding-right: 20px;
}

.uri-header {
    z-index: 2000;
    width: 100%;
    height: 60px;
    background-color: var(--ion-color-secondary);
    display: flex;
    justify-content: center;
    align-items: center;
}

.uri-form {
    margin-top: var(--uri-element-margin-vertical);
}

.uri-form > div {
    margin-left: calc(-1 * var(--uri-content-padding-horizontal));
    margin-right: calc(-1 * var(--uri-content-padding-horizontal));
}

.uri-form ion-item {
    --min-height: 40px;
}

.uri-form-error {
    display: flex;
    align-items: center;
    font-size: 14px;
    padding: 4px var(--uri-content-padding-horizontal) 12px var(--uri-content-padding-horizontal);
    color: var(--ion-color-danger);
}

.uri-form-error ion-icon {
    font-size: 20px;
    margin-right: 6px;
}

.uri-checkbox-item.with-description {
    align-items: flex-start;
}

.uri-checkbox-item.with-description.checked {
    --background: #f4f5f8;
}

.uri-checkbox-item.with-description h3 {
    margin-top: 16px;
    font-size: 17px;
    font-weight: bold;
    margin-bottom: 5px;
    color: var(--ion-color-dark);
}

.uri-checkbox-item.with-description p {
    color: var(--ion-color-medium);
    margin-bottom: 12px;
}

.uri-checkbox-item.with-description ion-checkbox {
    margin-top: 24px;
}

.uri-divider {
    font-size: 12px;
    color: var(--ion-color-dark);
    margin-left: calc(-1 * var(--uri-content-padding-horizontal));
    margin-right: calc(-1 * var(--uri-content-padding-horizontal));
    padding-top: 10px;
    padding-bottom: 10px;
    text-transform: uppercase;
    text-align: center;
}

.uri-divider > div {
    width: 100%;
    display: flex;
    align-items: center;
}

.uri-divider > div::before,
.uri-divider > div::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid var(--ion-color-light);
}

.uri-divider > div::before {
    margin-right: 15px;
}

.uri-divider > div::after {
    margin-left: 15px;
}

.uri-support-link {
    font-size: 13px;
    color: var(--ion-color-medium);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.uri-page-image {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.uri-page-image img {
    width: 250px;
    margin-bottom: 32px;
}

.uri-status-indicator {
    display: flex;
    font-size: 17px;
    font-weight: bold;
    align-items: center;
    padding-bottom: 8px;
    color: var(--ion-color-dark);
}

.uri-status-indicator::before {
    content: '';
    height: 10px;
    width: 10px;
    border-radius: 50%;
    margin-right: 10px;
}

.uri-status-indicator.red::before {
    background-color: var(--ion-color-danger);
}

.uri-status-indicator.green::before {
    background-color: var(--ion-color-success);
}

ion-item::part(detail-icon) {
    align-self: center;
}

.uriux-alert-terms .sc-ion-alert-md .alert-button {
    width: 100%;
    border-radius: 0;
    border-top: 1px solid #cdcdcd;
    padding-left: 0;
    padding-right: 0;
    margin-inline-end: 0;
}

.uriux-alert-terms .sc-ion-alert-md .alert-button:last-child {
    font-weight: bold;
}

.uriux-alert-terms .sc-ion-alert-md .alert-button-inner {
    justify-content: center;
}

.uriux-alert-terms .sc-ion-alert-md .alert-button-group {
    padding-bottom: 0;
    padding-inline-start: 0;
    padding-inline-end: 0;
}

.uri-input {
    width: 100%;
    flex: 1;
    z-index: 2;
    align-items: center;
    max-width: 100%;
    max-height: 100%;
    border: 0;
    outline: none;
    padding-left: 0;
    color: var(--ion-color-dark) !important;
    background-color: transparent;
}

.uri-input::placeholder {
    color: var(--ion-color-dark) !important;
    opacity: 0.5;
}

.uri-input[disabled] {
    color: #999 !important;
    cursor: not-allowed;
}

.uri-input:-webkit-autofill,
.uri-input:-webkit-autofill:hover,
.uri-input:-webkit-autofill:focus,
.uri-input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 100px rgb(255, 255, 255) inset;
    -webkit-text-fill-color: var(--ion-color-dark);
}

.uri-welcome-banner {
    background-image: url('../assets/img/uri-welcome-bg.png');
    background-size: cover;
    background-position: 50% 0%;
    background-repeat: no-repeat;
    height: 150px;
    margin-left: calc(-1 * var(--uri-content-padding-horizontal));
    margin-right: calc(-1 * var(--uri-content-padding-horizontal));
}

@media (min-width: 768px) {
    .uri-info-modal {
        --max-width: 420px;
        --max-height: 450px;
    }
    .uri-welcome-banner {
        height: 288px;
    }
}

.uri-input-otp {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    width: 386px;
}

.uri-input-otp > input {
    position: absolute;
    top: 10px;
    left: 0px;
    padding-left: 10px;
    font-family: monospace;
    color: var(--ion-color-uri-blue) !important;
    font-weight: bold;
    border: none;
    font-size: 40px;
    letter-spacing: 38px;
    /* pointer-events: none; */
    -moz-appearance: textfield;
}

.uri-input-otp > input::-webkit-outer-spin-button,
.uri-input-otp > input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.uri-input-otp > div {
    display: grid;
    grid-auto-flow: column;
    grid-column-gap: 18px;
}

.uri-input-otp > div > div {
    height: 70px;
    width: 44px;
    border: 1px solid var(--ion-color-light-tint);
    border-radius: 4px;
}

.uri-modal-form {
    padding-left: var(--uri-content-padding-horizontal) !important;
    padding-right: var(--uri-content-padding-horizontal) !important;
}

.uri-input-phone {
    padding-left: 120px;
    letter-spacing: 1px;
}

.country-select ion-item {
    cursor: pointer;
}

.country-select ion-item:hover {
    --background: #efefef;
}

.country-select .flag {
    width: 36px;
    height: 22px;
    margin-right: 12px;
}

.uri-input-phone + .country {
    z-index: 10;
    cursor: pointer;
    position: absolute;
    left: 0;
    bottom: 8px;
    letter-spacing: 1px;
    color: #999;
}

.uri-input-phone + .country::after {
    content: '';
    position: absolute;
    top: 7px;
    left: 48px;
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 6px solid #000;
}

.uri-input-phone + .country .flag {
    width: 36px;
    height: 22px;
    margin-right: 30px;
}

.study-list-item {
    --padding-start: 0;
    --inner-padding-end: 0;
    border-bottom: 0.5px solid #d9d9d9;
}

.study-details-page.study-list-item {
    border-bottom: 0;
}

.study-list-item .wrapper {
    padding: 20px 10px;
    background: #fff;
    display: flex;
    flex-direction: column;
    flex: 0 0 100%;
    gap: 20px;
}

@media (min-width: 768px) {
    .study-list-item .wrapper {
        padding: 20px;
    }
}
.study-list-item .image {
    background-size: cover;
    background-position: 50% 0%;
    background-blend-mode: color, normal;
    border-radius: 5px 5px 5px 5px;
    height: 200px;
    width: 100%;
}

.study-list-item .image-consumer-1 {
    background-image: linear-gradient(107.2deg, #ff6600 0%, #316094 100%), url('../../assets/img/consumer-1.jpg');
}
.study-list-item .image-consumer-2 {
    background-image: linear-gradient(107.2deg, #ff6600 0%, #316094 100%), url('../../assets/img/consumer-2.jpg');
}
.study-list-item .image-consumer-3 {
    background-image: linear-gradient(107.2deg, #ff6600 0%, #316094 100%), url('../../assets/img/consumer-3.jpg');
}

.study-list-item .image-gamer-1 {
    background-image: linear-gradient(107.2deg, #ff6600 0%, #316094 100%), url('../../assets/img/gamer-1.jpg');
}
.study-list-item .image-gamer-2 {
    background-image: linear-gradient(107.2deg, #ff6600 0%, #316094 100%), url('../../assets/img/gamer-2.jpg');
}
.study-list-item .image-gamer-3 {
    background-image: linear-gradient(107.2deg, #ff6600 0%, #316094 100%), url('../../assets/img/gamer-3.jpg');
}

.study-list-item .image-information-worker-1 {
    background-image: linear-gradient(107.2deg, #ff6600 0%, #316094 100%), url('../../assets/img/information-worker-1.jpg');
}
.study-list-item .image-information-worker-2 {
    background-image: linear-gradient(107.2deg, #ff6600 0%, #316094 100%), url('../../assets/img/information-worker-2.jpg');
}
.study-list-item .image-information-worker-3 {
    background-image: linear-gradient(107.2deg, #ff6600 0%, #316094 100%), url('../../assets/img/information-worker-3.jpg');
}

.study-list-item .image-technology-professional-1 {
    background-image: linear-gradient(107.2deg, #ff6600 0%, #316094 100%), url('../../assets/img/technology-professional-1.jpg');
}
.study-list-item .image-technology-professional-2 {
    background-image: linear-gradient(107.2deg, #ff6600 0%, #316094 100%), url('../../assets/img/technology-professional-2.jpg');
}
.study-list-item .image-technology-professional-3 {
    background-image: linear-gradient(107.2deg, #ff6600 0%, #316094 100%), url('../../assets/img/technology-professional-3.jpg');
}

.study-list-item .image-accessibility-1,
.study-list-item .image-miscellaneous-1 {
    background-image: linear-gradient(107.2deg, #ff6600 0%, #316094 100%), url('../../assets/img/miscellaneous-1.jpg');
}
.study-list-item .image-miscellaneous-2,
.study-list-item .image-accessibility-2 {
    background-image: linear-gradient(107.2deg, #ff6600 0%, #316094 100%), url('../../assets/img/miscellaneous-2.jpg');
}
.study-list-item .image-miscellaneous-3,
.study-list-item .image-accessibility-3 {
    background-image: linear-gradient(107.2deg, #ff6600 0%, #316094 100%), url('../../assets/img/miscellaneous-3.jpg');
}

.study-list-item .image-traveler-1 {
    background-image: linear-gradient(107.2deg, #ff6600 0%, #316094 100%), url('../../assets/img/traveler-1.jpg');
}
.study-list-item .image-traveler-2 {
    background-image: linear-gradient(107.2deg, #ff6600 0%, #316094 100%), url('../../assets/img/traveler-2.jpg');
}
.study-list-item .image-traveler-3 {
    background-image: linear-gradient(107.2deg, #ff6600 0%, #316094 100%), url('../../assets/img/traveler-3.jpg');
}

.study-list-item .featured {
    position: absolute;
    left: 10px;
    top: 20px;
    font-weight: 500;
    font-size: 14px;
}

@media (min-width: 768px) {
    .study-list-item .featured {
        left: 20px;
    }
}

.study-details-page.study-list-item .featured {
    left: 22px;
    top: 64px;
    position: absolute;
    z-index: 3000;
}

.study-list-item .featured-chip {
    display: flex;
    flex-direction: row;
    gap: 5px;
    padding: 4px 8px;
    margin: 10px;
    border-radius: 5px;
    background: #f5f5f5;
    color: #000;
    font-weight: 600;
}

.study-list-item .header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
}

.study-list-item .profile-title {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    gap: 10px;
}

.study-list-item .title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* justify-content: center; */
    flex-grow: 1;
    font-size: 16px;
    font-weight: bold;
    color: #316095;
}

.study-list-item .share {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.study-list-item .description {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-grow: 1;
    font-size: 14px;
    color: #595959;
}

.study-details-page.study-list-item .description {
    font-size: 16px;
    color: #595959;
}
.study-list-item .type-gratuity-wrapper {
    display: flex;
    align-items: center;
    gap: 40px;
    align-self: stretch;
}
.study-list-item .type {
    color: #000;
    font-size: 14px;
    font-weight: bold;
    display: flex;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
}

.study-list-item .location {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 5px;
    font-size: 14px;
    color: #595959;
}

.study-list-item .duration-location {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 5px;
    font-size: 14px;
    color: #595959;
}

.study-list-item .gratuity {
    font-weight: bold;
    font-size: 18px;
    color: #000;
    display: flex;
    padding: 0px 10px;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
}

.study-details-page.study-list-item .apply-button {
    display: none;
}

.study-list-item .study-participation {
    width: 100%;
}

.study-list-item .participation-details {
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-size: 14px;
    padding: 8px 16px;
    border-radius: 5px;
    background: #f5f5f5;
    color: #262626;
}
.study-list-item .participation-detail {
    display: flex;
    flex-direction: row;
    gap: 5px;
}

:root {
    --uri-content-padding-horizontal: 11px;
    --uri-element-margin-vertical: 20px;
    --uri-text-margin-vertical: 10px;
    --uri-toolbar-height: 60px;
}

p {
    color: var(--ion-text);
}

h1 {
    font-size: 34px;
    font-weight: bold;
    color: var(--ion-color-primary);
    margin-top: 24px;
}

h2 {
    font-size: 24px;
    font-weight: bold;
    color: var(--ion-color-primary);
}

h4 {
    font-weight: bold;
    color: var(--ion-color-dark);
    font-size: 17px;
}

ion-split-pane {
    --side-max-width: 23%;
}

ion-button {
    margin: var(--uri-element-margin-vertical) 0;
    text-transform: none;
}

ion-input {
    --color: var(--ion-color-dark) !important;
}

ion-button.button-clear {
    text-decoration: underline;
    margin: 8px 0;
}

ion-checkbox {
    --border-width: 1px;
}

ion-checkbox + ion-label {
    --color: var(--ion-color-dark) !important;
}

ion-toolbar ion-button.button-clear {
    text-decoration: none;
    margin: 0;
}

ion-list-header {
    padding-top: 16px;
    --color: var(--ion-color-primary);
}

ion-list-header > ion-label {
    font-weight: bold;
    font-size: 17px;
    --ion-color-base: var(--ion-color-dark);
    padding-bottom: 8px;
}

ion-segment.ios {
    margin: var(--uri-element-margin-vertical) auto;
    width: max-content;
    justify-content: space-between;
}

ion-segment.ios > ion-segment-button {
    /* flex: 0 1 0; */
}

ion-chip.ion-color-primary {
    background: var(--ion-color-primary);
    color: var(--ion-color-contrast);
}

ion-checkbox.md + ion-label {
    padding-left: 12px;
}

ion-item-divider.md > ion-label {
    text-transform: uppercase;
    color: var(--ion-color-medium) !important;
}

ion-item-divider.md > ion-icon[slot='end'] {
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 13px;
}

ion-item-divider.ios > ion-icon[slot='end'] {
    margin-right: 4px;
}

ion-segment-button.md {
    font-weight: bold;
}

ion-chip {
    color: var(--ion-color-dark);
}

/* 
disables tooltips since we can't control the title
element inside the SVG. This has the side effect of
preventing onClick from being used directly on the
icon component.
see: https://github.com/ionic-team/ionicons/issues/838
*/
ion-icon,
ion-item::part(detail-icon) {
    pointer-events: none;
}

.grecaptcha-badge {
    visibility: hidden;
}

@media (min-width: 992px) {
    .unauthenticated ion-header {
        display: none;
    }
    .unauthenticated h1 {
        margin-top: 12px;
    }
    ion-item-divider.md > ion-icon[slot='end'] {
        margin-right: 16px;
    }
}

@media (min-width: 1600px) and (min-height: 836px) {
    #bg {
        width: 100%;
        height: 100%;
        position: relative;
        background-color: #17273bf5;
        background-image: url('../assets/img/desktop-bg-pattern.png'), url('../assets/img/desktop-bg-pattern.png'), url('../assets/img/desktop-bg-pattern.png'),
            url('../assets/img/desktop-bg-pattern.png'), url('../assets/img/desktop-bg-pattern.png'), url('../assets/img/desktop-bg-pattern.png'),
            url('../assets/img/desktop-bg-pattern.png'), url('../assets/img/desktop-bg-pattern.png'), url('../assets/img/desktop-bg-pattern.png'),
            url('../assets/img/desktop-bg-pattern.png');
        background-repeat: repeat-x;
        background-position: 0px 0px, 600px 200px, 200px 400px, 800px 600px, 0px 800px, 600px 1000px, 200px 1200px, 800px 1400px, 0px 1600px;
        background-blend-mode: overlay;
    }

    #wrapper {
        width: 100%;
        height: calc(100% - 40px);
        max-width: 1600px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
        display: flex;
        flex-direction: column;
    }

    #app {
        position: relative;
        width: 100%;
        height: 100%;
        border-radius: 4px;
        border: 1px solid #fff;
        background-color: #fff;
    }

    ion-split-pane {
        border-radius: 4px;
    }

    ion-content {
        --background: #fff;
    }

    #desktop-logo {
        min-height: 90px;
        height: 120px;
        position: relative;
        bottom: -8px;
    }

    #desktop-info {
        display: grid !important;
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: 32px;
        padding: 12px 12px 0 12px;
    }

    #desktop-info > div {
        padding-left: 64px;
        position: relative;
    }

    #desktop-info > div::before {
        content: '';
        position: absolute;
        left: 0;
        width: 40px;
        height: 40px;
        background-size: contain;
        top: 20px;
    }

    #info-faqs::before {
        background-image: url('../assets/icon/question-mark.png');
    }

    #info-support::before {
        background-image: url('../assets/icon/envelope.png');
    }

    #info-privacy::before {
        background-image: url('../assets/icon/person.png');
    }

    #desktop-info,
    #desktop-info a,
    #desktop-terms a {
        color: #fff;
    }

    #desktop-info h3 {
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 8px;
    }

    #desktop-info p {
        font-size: 16px;
        margin-top: 0;
    }
}

.status-bar {
    display: flex;
    width: 100%;
    justify-content: center;
    background: #f5f5f5;
    padding: 10px;
    border-radius: 5px;
    font-size: 14px;
    color: #595959;
    gap: 10px;
}
.status-bar > div {
    display: flex;
    align-items: center;
    justify-content: center;
}

.similar-studies {
    display: flex;
    gap: 20px;
    overflow-x: auto;
    margin-bottom: 20px;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
}

.similar-studies::-webkit-scrollbar {
    display: none;
}

.similar-study {
    display: flex;
    flex: 0 0 auto;
    border: 0.5px solid #bfbfbf;
    border-radius: 5px;
    padding: 10px;
    width: 300px;
    overflow-x: hidden;
    gap: 20px;
}

@keyframes bounce {
    from {
        transform: translateY(0px);
    }
    to {
        transform: translateY(-5px);
    }
}

.box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 50%;
    animation: bounce 300ms alternate infinite cubic-bezier(0.2, 0.65, 0.6, 1);
    filter: drop-shadow(0 0 0.15rem #000);
}

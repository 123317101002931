ion-menu ion-content {
    --padding-bottom: 20px;
    --background: var(--ion-item-background, var(--ion-background-color, #fff));
    position: relative;
}

ion-menu ion-item {
    --transition: none;
    --padding-start: 18px;
    --min-height: 50px;
    margin-right: 10px;
    border-radius: 0 50px 50px 0;
    font-weight: 400;
    color: var(--ion-color-dark);
}

ion-menu ion-item ion-icon {
    color: var(--ion-color-dark);
}

ion-item.selected {
    color: var(--ion-color-white);
}

ion-menu ion-list {
    padding: 20px 0;
}

ion-menu ion-item.selected {
    --background: var(--ion-color-secondary);
    color: var(--ion-color-white);
}

ion-menu ion-item.selected:hover {
    --color: var(--ion-color-white);
}

ion-menu ion-item:hover {
    --color: var(--ion-color-primary);
}

ion-menu ion-item.selected ion-icon {
    color: var(--ion-color-white);
}

ion-menu ion-list:not(:last-of-type) {
    border-bottom: 1px solid var(--ion-color-step-150, #d7d8da);
}

.uriux-footer {
    height: 130px;
    padding-left: 20px;
    padding-top: 32px;
    width: 100%;
    z-index: 1000;
}

@media (min-height: 740px) {
    .uriux-footer {
        padding-top: 0;
        position: absolute;
        bottom: 0;
    }
}

.uriux-footer-logo {
    height: 27px;
    width: 150px;
    margin-top: 20px;
    margin-bottom: 20px;
    cursor: pointer;
}

.uriux-footer-copy {
    font-size: 12px;
    color: var(--ion-color-medium);
    margin-bottom: 20px;
}

/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

:root[mode='md'] {
    --ion-font-family: 'proxima-nova';
}

:root {
    --ion-text-color: var(--ion-color-medium);
    --ion-text-color-rgb: var(--ion-color-medium-rgb);

    --ion-color-primary: var(--ion-color-uri-blue);
    --ion-color-primary-rgb: var(--ion-color-uri-blue-rgb);
    --ion-color-primary-contrast: var(--ion-color-uri-blue-contrast);
    --ion-color-primary-contrast-rgb: var(--ion-color-uri-blue-contrast-rgb);
    --ion-color-primary-shade: var(--ion-color-uri-blue-shade);
    --ion-color-primary-tint: var(--ion-color-uri-blue-tint);

    --ion-color-secondary: var(--ion-color-uri-dark-blue);
    --ion-color-secondary-rgb: var(--ion-color-uri-dark-blue-rgb);
    --ion-color-secondary-contrast: var(--ion-color-uri-dark-blue-contrast);
    --ion-color-secondary-contrast-rgb: var(--ion-color-uri-dark-blue-contrast-rgb);
    --ion-color-secondary-shade: var(--ion-color-uri-dark-blue-shade);
    --ion-color-secondary-tint: var(--ion-color-uri-dark-blue-tint);

    --ion-color-tertiary: var(--ion-color-uri-light-blue);
    --ion-color-tertiary-rgb: var(--ion-color-uri-light-blue-rgb);
    --ion-color-tertiary-contrast: var(--ion-color-uri-light-blue-contrast);
    --ion-color-tertiary-contrast-rgb: var(--ion-color-uri-light-blue-contrast-rgb);
    --ion-color-tertiary-shade: var(--ion-color-uri-light-blue-shade);
    --ion-color-tertiary-tint: var(--ion-color-uri-light-blue-tint);

    --ion-color-success: #2fd36f;
    --ion-color-success-rgb: 47, 211, 111;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0, 0, 0;
    --ion-color-success-shade: #29ba62;
    --ion-color-success-tint: #44d77d;

    --ion-color-warning: #fdc40c;
    --ion-color-warning-rgb: 253, 196, 12;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #dfac0b;
    --ion-color-warning-tint: #fdca24;

    --ion-color-danger: #ec445a;
    --ion-color-danger-rgb: 236, 68, 90;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #d03c4f;
    --ion-color-danger-tint: #ee576b;

    --ion-color-dark: #222428;
    --ion-color-dark-rgb: 34, 36, 40;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #1e2023;
    --ion-color-dark-tint: #383a3e;

    --ion-color-medium: #5c5c5c;
    --ion-color-medium-rgb: 92, 92, 92;
    --ion-color-medium-contrast: #ffffff;
    --ion-color-medium-contrast-rgb: 255, 255, 255;
    --ion-color-medium-shade: #515151;
    --ion-color-medium-tint: #6c6c6c;

    --ion-color-light: #d5d5d5;
    --ion-color-light-rgb: 213, 213, 213;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #bbbbbb;
    --ion-color-light-tint: #d9d9d9;

    --ion-color-uri-dark-blue: #14273d;
    --ion-color-uri-dark-blue-rgb: 20, 39, 61;
    --ion-color-uri-dark-blue-contrast: #ffffff;
    --ion-color-uri-dark-blue-contrast-rgb: 255, 255, 255;
    --ion-color-uri-dark-blue-shade: #122236;
    --ion-color-uri-dark-blue-tint: #2c3d50;

    --ion-color-uri-blue: #316095;
    --ion-color-uri-blue-rgb: 49, 96, 149;
    --ion-color-uri-blue-contrast: #ffffff;
    --ion-color-uri-blue-contrast-rgb: 255, 255, 255;
    --ion-color-uri-blue-shade: #2b5483;
    --ion-color-uri-blue-tint: #4670a0;

    --ion-color-uri-light-blue: #4781c2;
    --ion-color-uri-light-blue-rgb: 71, 129, 194;
    --ion-color-uri-light-blue-contrast: #ffffff;
    --ion-color-uri-light-blue-contrast-rgb: 255, 255, 255;
    --ion-color-uri-light-blue-shade: #3e72ab;
    --ion-color-uri-light-blue-tint: #598ec8;

    --ion-color-white: #ffffff;
    --ion-color-white-rgb: 255, 255, 255;
    --ion-color-white-contrast: var(--ion-color-uri-blue);
    --ion-color-white-contrast-rgb: var(--ion-color-uri-blue-rgb);
    --ion-color-white-shade: #e0e0e0;
    --ion-color-white-tint: #ffffff;

    --ion-color-apple: #000000;
    --ion-color-apple-rgb: 0, 0, 0;
    --ion-color-apple-contrast: #ffffff;
    --ion-color-apple-contrast-rgb: 255, 255, 255;
    --ion-color-apple-shade: #000000;
    --ion-color-apple-tint: #1a1a1a;

    --ion-color-linkedin: #2c65bc;
    --ion-color-linkedin-rgb: 44, 101, 188;
    --ion-color-linkedin-contrast: #ffffff;
    --ion-color-linkedin-contrast-rgb: 255, 255, 255;
    --ion-color-linkedin-shade: #2759a5;
    --ion-color-linkedin-tint: #4174c3;

    --ion-color-facebook: #3b5998;
    --ion-color-facebook-rgb: 59, 89, 152;
    --ion-color-facebook-contrast: #ffffff;
    --ion-color-facebook-contrast-rgb: 255, 255, 255;
    --ion-color-facebook-shade: #344e86;
    --ion-color-facebook-tint: #4f6aa2;

    --ion-item-color: var(--ion-color-dark);
}

.ion-color-apple {
    --ion-color-base: var(--ion-color-apple);
    --ion-color-base-rgb: var(--ion-color-apple-rgb);
    --ion-color-contrast: var(--ion-color-apple-contrast);
    --ion-color-contrast-rgb: var(--ion-color-apple-contrast-rgb);
    --ion-color-shade: var(--ion-color-apple-shade);
    --ion-color-tint: var(--ion-color-apple-tint);
}

.ion-color-linkedin {
    --ion-color-base: var(--ion-color-linkedin);
    --ion-color-base-rgb: var(--ion-color-linkedin-rgb);
    --ion-color-contrast: var(--ion-color-linkedin-contrast);
    --ion-color-contrast-rgb: var(--ion-color-linkedin-contrast-rgb);
    --ion-color-shade: var(--ion-color-linkedin-shade);
    --ion-color-tint: var(--ion-color-linkedin-tint);
}

.ion-color-facebook {
    --ion-color-base: var(--ion-color-facebook);
    --ion-color-base-rgb: var(--ion-color-facebook-rgb);
    --ion-color-contrast: var(--ion-color-facebook-contrast);
    --ion-color-contrast-rgb: var(--ion-color-facebook-contrast-rgb);
    --ion-color-shade: var(--ion-color-facebook-shade);
    --ion-color-tint: var(--ion-color-facebook-tint);
}

ion-button:hover {
    --ion-color-primary: var(--ion-color-uri-light-blue);
    --ion-color-primary-rgb: var(--ion-color-uri-light-blue-rgb);
    --ion-color-primary-contrast: var(--ion-color-uri-light-blue-contrast);
    --ion-color-primary-contrast-rgb: var(--ion-color-uri-light-blue-contrast-rgb);
    --ion-color-primary-shade: var(--ion-color-uri-light-blue-shade);
    --ion-color-primary-tint: var(--ion-color-uri-light-blue-tint);
}

ion-button:active {
    --ion-color-primary: var(--ion-color-uri-dark-blue);
    --ion-color-primary-rgb: var(--ion-color-uri-dark-blue-rgb);
    --ion-color-primary-contrast: var(--ion-color-uri-dark-blue-contrast);
    --ion-color-primary-contrast-rgb: var(--ion-color-uri-dark-blue-contrast-rgb);
    --ion-color-primary-shade: var(--ion-color-uri-dark-blue-shade);
    --ion-color-primary-tint: var(--ion-color-uri-dark-blue-tint);
}

/* 
https://github.com/ionic-team/ionic-conference-app/blob/master/src/theme/variables.scss
https://github.com/ionic-team/ionic-framework/tree/master/core/src/components/button#css-custom-properties
*/
